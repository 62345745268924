import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import HeroImg from '../Assets/hero.webp';
import WhatsappContact from './contact';
import { Link } from 'react-router-dom';

const Hero = () => {
  const [showWaitlist, setShowWaitlist] = useState(true);

  const scrollToOutfit = () => {
    const outfitSection = document.getElementById("full-body-outfit");
    outfitSection.scrollIntoView({ behavior: "smooth" });
  };

  const handleJoinClick = () => {
    setShowWaitlist(false);
    console.log('User joined the waitlist');
  };
  
  const handleWaitClick = () => {
    console.log('Show waitlist form');
  };

  return (
    <div className="relative">
      {/* Hero Section */}
      <div className="relative">
        {/* Hero Image */}
        <div className="relative h-[300px] sm:h-[80vh] lg:h-[100vh] mt-0 ">
          <img 
            src={HeroImg} 
            alt="Hero" 
            className="w-full h-full object-cover"
          />
          {/* Gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-[#3D291D] to-transparent z-10"></div>

          {/* Text and Button in the center of the image */}
          <div className="absolute inset-0 flex flex-col items-center justify-center px-4 z-20">
            <h1 className="text-[#FDF5F2] text-3xl sm:text-4xl md:text-6xl lg:text-8xl font-gothamBold text-center mt-32 sm:mt-12 lg:mt-32">
              A state of mind.
            </h1>

            {/* Adjusted Button for Mobile and Desktop */}
            <Link to="/categories"> {/* Wrap button with Link */}
      <button
        className="text-[#FDF5F2] font-gothamBold text-sm sm:text-base md:text-xl lg:text-2xl border-2 border-white rounded-full px-4 sm:px-6 md:px-8 lg:px-10 py-1 sm:py-2 md:py-3 lg:py-4 hover:bg-white hover:text-[#3D291D] transition duration-300 mt-4 sm:mt-3 lg:mt-6 z-20"
      >
        Shop now
      </button>
    </Link>

            {/* Arrow Down Icon */}
            <IoIosArrowDown className="text-[#FDF5F2] text-4xl sm:text-3xl md:text-5xl mt-2 sm:mt-3 lg:mt-7 z-20" />
          </div>

    
          <div className="fixed bottom-32 right-4 lg:right-8 z-50">
            <WhatsappContact />
          </div> 

        </div>
      </div>
    </div>
  );
};

export default Hero;
