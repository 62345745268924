import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../Assets/navLogo.png';
import { LuShoppingCart, LuHeart, LuSearch } from 'react-icons/lu';
import { IoMenu } from 'react-icons/io5';
import { IoClose } from 'react-icons/io5';
import '../index.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Add state to track login status

  // Toggle menu function for mobile
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Handle scroll event to change navbar background and add shadow
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0); // Set to true if scrolled down
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Simulating a user login check (you can replace this with real authentication logic)
    const userToken = localStorage.getItem('userToken'); // Example token check
    setIsLoggedIn(!!userToken); // Set to true if the token exists
  }, []);

  const handleNavigation = (path) => {
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };

  const scrollToFooter = () => {
    const footer = document.getElementById('footer');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    } 
  };

  const scrollToOutfit = () => {
    const outfitSection = document.getElementById("full-body-outfit");
    outfitSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <nav className={`p-2 w-full navbar-gradient ${isScrolled ? 'bg-[#3D291D] shadow-lg' : 'bg-transparent'} transition-shadow duration-300 ease-in-out`}>
      <div className="w-full flex items-center justify-between font-gotham px-4 lg:px-12">

        <div className="mobile-menu">
          {!isLoggedIn && (
            <div className="auth-links">
              <Link to="/signup" className="signup-link">Sign Up</Link>
              <Link to="/login" className="login-link">Log In</Link>
            </div>
          )}
        </div>

        <div className="flex items-center">
          <button className="lg:hidden text-white mr-4 relative" onClick={toggleMenu}>
            <IoMenu className="w-5 h-5" />
          </button>
          <Link to="/" onClick={() => handleNavigation('/')} className="ml-4 lg:ml-12">
            <img src={logo} alt="Logo" className="h-7 w-auto lg:h-12" />
          </Link>

          {/* Desktop Navigation links */}
          <ul className="hidden lg:flex space-x-6 ml-12 items-center font-gothamLight mt-3">
            <li>
              <Link to="/" onClick={() => handleNavigation('/')} className="text-white hover:text-gray-400 no-underline text-sm">About Us</Link>
            </li>
            <span className="text-white">|</span>
            <li>
              <Link to="/categories" onClick={() => handleNavigation('/categories')} className="text-white hover:text-gray-400 no-underline text-sm">Shop</Link>
            </li>
            <span className="text-white">|</span>
            <li>
              <button onClick={scrollToFooter} className="text-white hover:text-gray-400 no-underline text-sm">Contact Us</button>
            </li>
          </ul>
        </div>

        {/* Icons */}
        <div className="flex items-center space-x-3 lg:mr-12">
          {isLoggedIn && (
            <>
              <LuHeart className="text-white w-5 h-5 cursor-pointer" />
              <LuShoppingCart className="text-white w-5 h-5 cursor-pointer" />
            </>
          )}
          {!isLoggedIn && (
            <>
              <Link to="/signup" onClick={() => handleNavigation('/signup')} className="signup-link-desktop">Sign Up</Link>
              <Link to="/login" onClick={() => handleNavigation('/login')} className="login-link-desktop">Log In</Link>
            </>
          )}
        </div>
      </div>

      {/* Mobile Search Bar under the Logo */}
      <div className="lg:hidden flex flex-col items-center mt-2">
        <div className="relative mb-4 w-full px-4">
          <input
            type="text"
            placeholder="Search..."
            className="w-full bg-transparent border border-white rounded-full py-1 pl-10 pr-3 text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-white text-xs"
          />
          <LuSearch className="absolute left-8 top-1/2 transform -translate-y-1/2 text-white w-4 h-4" />
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="absolute top-12 left-4 right-4 z-40 bg-[#3D291D] rounded-lg p-4 w-40 shadow-lg">
          <button className="absolute top-2 right-2 text-white" onClick={toggleMenu}>
            <IoClose className="w-5 h-5" />
          </button>
          {/* Ensure items are aligned in a column */} 
          <div className="flex flex-col items-start space-y-4 text-left"> 
            <Link to="/" onClick={() => handleNavigation('/')} className="text-white text-sm hover:text-gray-400 no-underline">About Us</Link>
            <Link to="/categories" onClick={() => handleNavigation('/categories')} className="text-white text-sm hover:text-gray-400 no-underline">Categories</Link>
            <button onClick={scrollToFooter} className="text-white text-sm hover:text-gray-400 no-underline">Contact Us</button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
