import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

const WhatsappContact = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleOptionClick = (phoneNumber) => {
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
    setShowOptions(false);
  };

  const handleClose = () => {
    setShowOptions(false);
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      className="p-2 md:p-4 rounded-2xl flex items-center space-x-2 relative"
      style={{
        backgroundColor: '#FA8F21',
        color: '#FDF5F2',
        cursor: 'pointer',
        fontFamily: 'GothamBold',
        fontWeight: 'bold'
      }}
    >
      {/* Adjusted icon size for mobile */}
      <FaWhatsapp
        className="text-2xl md:text-3xl" // Mobile: 2xl size, Desktop: xl size
        onClick={() => setShowOptions(!showOptions)}
      />
      {/* Hide text on smaller screens */}
      <span
        onClick={() => setShowOptions(!showOptions)}
        className="hidden md:inline"
      >
        Contact via WhatsApp
      </span>
      
      {/* Side-by-side options */}
      {showOptions && (
        <div
          className="absolute right-0 md:left-0 mt-2 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 p-2 rounded-2xl shadow-md w-40 md:w-56"
          style={{
            backgroundColor: '#FDF5F2',
            color: '#FA8F21',
            fontFamily: 'GothamBold',
            fontWeight: 'bold',
            top: '100%'
          }}
        >
          <button
            className="px-2 md:px-3 py-1 rounded-md hover:bg-gray-200"
            onClick={() => handleOptionClick('+905362887229')}
            style={{ color: '#FA8F21' }}
          >
            World Wide
          </button>
          <span className="hidden md:inline mt-1" style={{ color: '#FA8F21', fontWeight: 'bold' }}>or</span>
          <button
            className="px-2 md:px-3 py-1 rounded-md hover:bg-gray-200"
            onClick={() => handleOptionClick('+9647774089987')}
            style={{ color: '#FA8F21' }}
          >
            Iraq
          </button>
        </div>
      )}
    </div>
  );
};

export default WhatsappContact;
