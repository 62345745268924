import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component
import Navbar from './components/navbar';
import Login from './pages/login/login';
import SignUp from './pages/signup/signup'; 
import Aboutus from './pages/aboutus/aboutus';
import Footer from './components/footer';
import Categories from './pages/categories';
import Products from './pages/products';
import Favorite from './pages/favorite';
import ProductDetail from './pages/productDetail';
import AddCart from './pages/addCart'

const App = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <ScrollToTop /> {/* Scrolls to top on each route change */}
        <Navbar /> {/* Navbar component */}
        <div className="flex-grow"> {/* Container for main content */}
          <Routes>
            <Route path="/" element={<Aboutus />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route
    path="/categories"
    element={<React.Fragment key={Date.now()}><Categories /></React.Fragment>}
  />
            <Route path="/products" element={<Products />} />
            <Route path="/cart" element={<AddCart/>} />
            <Route path="/favorites" element={<Favorite />} />
            <Route path="/productDetail/:slug" element={<ProductDetail />} /> {/* Dynamic route for product detail */}
          </Routes>
        </div>
        <Footer /> {/* Footer component */}
      </Router>
    </div>
  );
};

export default App;
