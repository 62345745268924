import React, { useState, useEffect } from 'react';
import { LuHeart } from 'react-icons/lu';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import WhatsappContact from '../components/contact';
import LoginSignupModal from '../components/LoginSignupModal'

const Spinner = () => (
  <div className="w-10 h-10 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
);

const ProductDetail = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]); // To store the list of products
  const [randomProducts, setRandomProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { slug } = useParams(); // Assumes you have slug-based routing
  const location = useLocation(); // Use location to get query params

  // Function to get query parameters
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };
  const handleActionClick = (actionType) => {
    const isLoggedIn = false; // Replace with actual login check
    if (!isLoggedIn) {
      setIsModalOpen(true);
    } else {
      // Proceed with the action if logged in
      console.log(`${actionType} action executed.`);
    }
  };
  const selectedColor = getQueryParam('color'); // Get the color from URL

  // Fetching product list
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://saarconcept.net/api/products-with-filters/');
        setProducts(response.data.products);

        // Set random products after fetching
        const shuffled = [...response.data.products].sort(() => 0.5 - Math.random());
        setRandomProducts(shuffled.slice(0, 4));
      } catch (error) {
        console.error("Error fetching product list:", error);
      }
    };

    fetchProducts();
  }, []);

  // Fetching product details based on slug
  useEffect(() => {
    const fetchProduct = async () => {
      if (slug) {
        try {
          const response = await axios.get(`https://saarconcept.net/api/products/${slug}/`);
          setProduct(response.data);
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      }
    };

    fetchProduct();
  }, [slug]);

  const handleNavigate = (url) => {
    navigate(url);
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };


  if (!product) {
    return (
      <div className='flex items-center justify-center min-h-screen bg-[#FDF5F2]'>
        <Spinner />
      </div>
    );
  }

  // Extract product details to use in JSX
  const { name, description, product_colors, category } = product;

  // Get main image and price based on selected color
  const mainImage = product_colors?.find(color => color.color.name === selectedColor)?.images?.find(image => image.is_feature)?.image;
  const price = product_colors?.find(color => color.color.name === selectedColor)?.base_price;

  // Filter out the selected color to get other colors
  const otherColors = product_colors.filter(color => color.color.name !== selectedColor);

  return (
    <div className="flex flex-col items-center min-h-screen bg-[#FDF5F2] p-4">
{/* Responsive Outer Container */}
<div className="flex items-start mx-4 w-full md:w-auto max-w-md md:max-w-full">
  {/* Left Big Square with Conditional Size */}
  <div 
    className={`bg-white border border-gray-300 
      ${otherColors.length === 1 ? 'w-64 h-96' : 'w-96 h-96'} 
      ${otherColors.length === 1 ? 'md:block hidden' : ''}`}
  >
    {mainImage && (
      <img 
        src={mainImage} 
        alt={name} 
        className="w-full h-full object-cover" 
      />
    )}
  </div>

  {/* Right Column with Conditional Box Size */}
  <div className="flex flex-col ml-0">
    {otherColors.length === 1 ? (
      // Only show the right image if there is exactly one color option
      otherColors.slice(0, 1).map((colorOption, index) => (
        <div 
          key={index}
          onClick={() => handleNavigate(`/productDetail/${product.slug}?color=${colorOption.color.name}`)}
          className="bg-white border border-gray-300 flex items-center justify-center cursor-pointer w-64 h-96 md:block hidden"
        >
          <div className="flex items-center justify-center w-full h-full">
            {colorOption.images.length > 0 && (
              <img
                src={colorOption.images[0].image}
                alt={colorOption.color.name}
                className="w-full h-full object-cover"
              />
            )}
          </div>
        </div>
      ))
    ) : (
      // Show both images if there are two color options
      otherColors.slice(0, 2).map((colorOption, index) => (
        <div 
          key={index}
          onClick={() => handleNavigate(`/productDetail/${product.slug}?color=${colorOption.color.name}`)}
          className="bg-white border border-gray-300 flex items-center justify-center cursor-pointer w-44 h-48"
        >
          <div className="flex items-center justify-center w-full h-full">
            {colorOption.images.length > 0 && (
              <img
                src={colorOption.images[0].image}
                alt={colorOption.color.name}
                className="w-full h-full object-cover"
              />
            )}
          </div>
        </div>
      ))
    )}
  </div>

 {/* Responsive Squares */}
{otherColors.length === 1 && (
  <div className="flex w-full justify-center gap-x-1 md:hidden">
    {/* Main Image Square */}
    <div className="w-64 h-96 cursor-pointer">
      {mainImage && (
        <img 
          src={mainImage} 
          alt={name} 
          className="w-full h-full object-cover" 
        />
      )}
    </div>

    {/* First Color Option Square */}
    <div 
      className="w-64 h-96 cursor-pointer"
      onClick={() => handleNavigate(`/productDetail/${product.slug}?color=${otherColors[0]?.color?.name || ''}`)}
    >
      {otherColors[0]?.images.length > 0 && (
        <img
          src={otherColors[0].images[0].image}
          alt={otherColors[0].color.name}
          className="w-full h-full object-cover"
        />
      )}
    </div>
  </div>
)}

        {/* Separator - visible only on medium screens and above */}
        <div className="hidden md:block w-px h-[24rem] bg-[#3D291D] mx-4" /> {/* Separator */}

        <div className="ml-4 mr-16 hidden md:block">
          <h2 className='font-gothamBold text-[#3D291D]'>{selectedColor} {name}</h2>
          <h5 className='font-gothamLight text-[#3D291D] mt-2'>{price}$</h5>

        {/* Show size buttons only if the name does not include "bag" */}
{!name.toLowerCase().includes('bag') && (
  <div className="flex space-x-4 mt-4">
    {['S', 'M', 'L'].map(size => (
      <div key={size} className="w-8 h-8 rounded-full border-2 border-[#3D291D] flex items-center justify-center">
        <span className="font-gothamLight text-[#3D291D]">{size}</span>
      </div>
    ))}
  </div>
)}


          <div className="hidden md:block">
            <h6 className='font-gothamLight text-[#3D291D] mt-4 underline'>Size Details</h6>
            <div className="flex justify-between w-full mt-4">
              <LuHeart
               onClick={() => handleActionClick('addToWishlist')}
               className="text-[#FA8F21] text-3xl" />
              <button
               onClick={() => handleActionClick('addToCart')}
               className="bg-[#FA8F21] text-sm font-gothamBold text-white py-2 px-4 rounded-3xl">
                Add to Cart
              </button>
            </div>
            <div className="w-96 h-px bg-[#3D291D] mt-4" />
            <h6 className='font-gothamBold text-[#3D291D] mt-2'>Description</h6>
            <div className='font-gothamMedium text-xs text-[#3D291D] mt-2 whitespace-pre-line overflow-wrap break-word w-96'>
              {description}
            </div>
          </div>
        </div>
      </div>

     {/* Mobile View */}
     <div className="mt-4 md:hidden text-left w-full px-4">
        {/* Name and Price */}
        <h2 className='font-gothamBold text-[#3D291D]'>{selectedColor} {name}</h2>
        <h5 className='font-gothamLight text-[#3D291D] mt-2'>{price}$</h5>

        {/* Size Buttons */}
        <div className="flex space-x-4 mt-4">
          {/* Show size buttons only if the name does not include "bag" */}
{!name.toLowerCase().includes('bag') && (
  <div className="flex space-x-4 mt-4">
    {['S', 'M', 'L'].map(size => (
      <div key={size} className="w-8 h-8 rounded-full border-2 border-[#3D291D] flex items-center justify-center">
        <span className="font-gothamLight text-[#3D291D]">{size}</span>
      </div>
    ))}
  </div>
)}

        </div>

        {/* Size Details and Add to Cart */}
        <div className="mt-4 underline font-gothamLight text-[#3D291D]">Size Details</div>
        <div className="flex justify-between w-full mt-4">
          <button 
           onClick={() => handleActionClick('addToCart')}
          className="bg-[#FA8F21] font-gothamBold text-white py-2 px-4 rounded-3xl">
            Add to Cart
          </button>
          <LuHeart
           onClick={() => handleActionClick('addToWishlist')}
           className="text-[#FA8F21] text-3xl" />
        </div>

        <div className="w-full h-px bg-[#3D291D] mt-4" /> {/* Separator */}

        {/* Description */}
        <h6 className='font-gothamBold text-[#3D291D] mt-2'>Description</h6>
        <p className='font-gothamMedium text-xs text-[#3D291D] mt-2 whitespace-pre-line'>
          {description}
        </p>
      </div>

      {/* "You might be interested in" Section for mobile view */}
      <div className="mt-4 md:hidden text-center">
        <h3 className="font-gothamBold text-[#3D291D] text-xl">You might be interested in</h3>
      </div>

      {/* "You might be interested in" Section for Desktop */}
      <div className="hidden md:block mt-8 text-center">
        <h3 className="font-gothamBold text-[#3D291D] text-2xl">You might be interested in</h3>
      </div>
      <div className="hidden md:flex flex-wrap justify-center mt-4 gap-3">
  {products
   .filter((productItem) => productItem.slug !== product.slug)
  .slice(0, 4)
  .map((productItem, index) => (
    <div
      key={index}
      onClick={() => handleNavigate(`/productDetail/${productItem.slug}?color=${productItem.product_colors[0]?.color.name}`)} // Navigate on click
      className="w-72 h-96 flex flex-col p-6 cursor-pointer"
    >
      <div className="w-full h-48 bg-gray-200 mb-4">
        {productItem.product_colors[0]?.images[0]?.image && (
          <img
            src={productItem.product_colors[0].images[0].image}
            alt={productItem.name}
            className="w-full h-full object-cover"
          />
        )}
      </div>
      <h4 className="font-gothamBold text-[#3D291D] text-lg self-start">{`${productItem.product_colors[0]?.color.name} ${productItem.name}`}</h4>
      <p className="font-gothamLight text-[#3D291D] text-md mt-1 self-start">
        ${productItem.product_colors[0]?.base_price}
      </p>
      <div className="flex justify-between items-center w-full mt-auto">
        <button 
          onClick={(e) => {
            e.stopPropagation(); // Prevent click from bubbling up to the parent div
            handleActionClick('addToCart');
          }}
          className="bg-[#FA8F21] font-bold font-gothamBold text-white py-2 px-4 rounded-3xl"
        >
          Add to Cart
        </button>
        <LuHeart 
          onClick={(e) => {
            e.stopPropagation(); // Prevent click from bubbling up to the parent div
            handleActionClick('addToWishlist');
          }}
          className="text-[#FA8F21] text-2xl" 
        />
      </div>
    </div>
  ))}
</div>

<div className="grid grid-cols-2 gap-0 mt-4 md:hidden">
  {products
   .filter((productItem) => productItem.slug !== product.slug)
  .slice(0, 4)
  .map((productItem, index) => (
    <div
      key={index}
      onClick={() => handleNavigate(`/productDetail/${productItem.slug}?color=${productItem.product_colors[0]?.color.name}`)} // Navigate on click
      className="w-full h-full flex flex-col p-2 no-underline cursor-pointer"
    >
      {productItem.product_colors[0]?.images[0]?.image ? (
        <img
          src={productItem.product_colors[0].images[0].image}
          alt={productItem.name}
          className="w-full h-36 object-cover mb-2"
        />
      ) : (
        <div className="w-full h-36 bg-gray-600 mb-2" />
      )}
      <h4 className="font-gothamBold text-[#3D291D] text-base">{`${productItem.product_colors[0]?.color.name} ${productItem.name}`}</h4>
      <p className="font-gothamLight text-[#3D291D] text-sm mt-1">${productItem.product_colors[0]?.base_price}</p>
      <div className="flex justify-between items-center mt-auto">
        <button 
          onClick={(e) => {
            e.stopPropagation(); // Prevent click from bubbling up to the parent div
            handleActionClick('addToCart');
          }}
          className="bg-[#FA8F21] text-sm font-bold font-gothamBold text-white py-2 px-3 rounded-3xl"
        >
          Add to Cart
        </button>
        <LuHeart
          onClick={(e) => {
            e.stopPropagation(); // Prevent click from bubbling up to the parent div
            handleActionClick('addToWishlist');
          }}
          className="text-[#FA8F21] text-2xl" 
        />
      </div>
    </div>
  ))}
</div>

<LoginSignupModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
<div className="fixed bottom-32 right-4 lg:right-8 z-50">
            <WhatsappContact />
          </div> 
  {/* Horizontal Rule with Custom Color */}
  <hr className="w-full h-px bg-[#3D291D] mt-8" />

    </div>
  );
};

export default ProductDetail;
