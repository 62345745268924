import React, { useEffect, useState } from 'react';
import img from '../Assets/Mask group.png';
import { IoIosArrowDown, IoMdImage } from 'react-icons/io';
import axios from 'axios';
import cat1 from '../Assets/cat1.png';
import cat2 from '../Assets/cat2.png';
import cat3 from '../Assets/cat3.png';
import WhatsappContact from '../components/contact';

const Spinner = () => (
  <div className="w-10 h-10 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
);

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);
  const handleNavigate = (url) => {
    window.location.href = url;
  };
  
  // Fetch categories from API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://saarconcept.net/api/categories-with-images/');
        console.log(response.data);
        setCategories(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Handle image loading error
  const handleImageError = (index, i) => {
    setImageError((prev) => ({
      ...prev,
      [`${index}-${i}`]: true,
    }));
  };

  return (
    <>
      {/* Image container */}
      <div className="relative w-full h-auto">
        <img src={img} alt="Top image" className="w-full h-auto object-cover" style={{ margin: 0 }} />

        {/* Centered "Categories" text and arrow */}
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <h1 className="text-white text-3xl sm:text-4xl md:text-5xl font-bold gothamBold">Categories</h1>
          <IoIosArrowDown className="text-white text-4xl sm:text-5xl md:text-6xl mt-4" />
        </div>
      </div>

      {/* Content section */}
      <div className="bg-[#FDF5F2] flex flex-col items-center justify-center min-h-screen p-4 space-y-8">
        {/* Top three smaller squares */}
        <div className="flex justify-center space-x-4 sm:space-x-8 lg:space-x-48 mb-4">
          {[cat1, cat2, cat3].map((catImg, idx) => (
            <div key={idx} className="w-24 h-24 sm:w-40 sm:h-40 flex items-center justify-center border-2 border-[#3D291D] rounded-3xl overflow-hidden">
              <img src={catImg} alt={`Image ${idx + 1}`} className="object-contain w-28 h-28 sm:w-48 sm:h-48 mb-2 sm:mb-6" />
            </div>
          ))}
        </div>

        {loading ? (
          <div className="flex flex-col justify-center items-center h-64">
            <Spinner />
          </div>
        ) : (
          categories.map((category, index) => (
  <div key={index} className="bg-[#FDF5F2] p-2 w-full max-w-xl sm:max-w-5xl">
              <div className="flex items-start ml-2">
                <a href="https://saarconcept.net/products" className="text-lg sm:text-xl font-bold gothamBold text-[#3D291D] text-left no-underline">
                  {category.name}
                </a>
              </div>

              <div className="flex justify-center space-x-2 sm:space-x-6 lg:space-x-8">
  {category.images.slice(0, 3).map((imageData, i) => (
    <div key={i} className="w-32 h-32 sm:w-80 sm:h-80 flex items-center justify-center overflow-hidden">
      {imageError[`${index}-${i}`] ? (
        <IoMdImage className="text-4xl text-gray-400" />
      ) : (
    <a
  href="#"
  onClick={(e) => {
    e.preventDefault();
    // Use image-specific slug and color from imageData
    const imageSlug = imageData.slug || 'default-slug'; // Fallback for image slug
    const color = imageData.color || 'default-color'; // Fallback for color
    handleNavigate(`/productDetail/${imageSlug}?color=${encodeURIComponent(color)}`);
  }}
  className="w-full h-full flex items-center justify-center"
>
          <img
            src={imageData.image}
            alt={`${category.name} ${imageData.color || ''}`}
            className="w-full h-full object-cover object-center"
            loading="lazy"
            onError={() => handleImageError(index, i)}
          />
        </a>
      )}
    </div>
  ))}


  {/* Add empty divs if there are less than 3 images */}
  {Array.from({ length: 3 - category.images.length }).map((_, i) => (
    <div key={`empty-${i}`} className="w-32 h-32 sm:w-80 sm:h-80 flex items-center justify-center">
      {/* Placeholder for missing image */}
    </div>
  ))}
</div>
            </div>
          ))
        )}

        <div className="fixed bottom-32 right-4 lg:right-8 z-50">
          <WhatsappContact />
        </div> 
      </div>
    </>
  );
};

export default Category;
