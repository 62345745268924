import React, { useEffect, useState } from 'react'; 
import { LuHeart } from 'react-icons/lu';
import { TiArrowRight } from 'react-icons/ti';
import { FiTrash } from 'react-icons/fi';
import { HiOutlinePlus } from 'react-icons/hi';
import { FaArrowRight } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';

const AddCart = () => {
  const [cartItems, setCartItems] = useState([]);

  // Fetch cart data from localStorage with default quantity of 1
  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cart')) || [];
    const updatedItems = storedCartItems.map(item => ({
      ...item,
      quantity: item.quantity || 1, // Default to 1 if quantity is undefined
      selectedSize: item.selectedSize || 'M', // Default size to M
    }));
    setCartItems(updatedItems);
  }, []);

  // Handle quantity increase
  const increaseQuantity = (index) => {
    const updatedItems = [...cartItems];
    updatedItems[index].quantity += 1;
    setCartItems(updatedItems);
    localStorage.setItem('cart', JSON.stringify(updatedItems)); // Save to localStorage
  };

  // Handle quantity decrease
  const decreaseQuantity = (index) => {
    const updatedItems = [...cartItems];
    if (updatedItems[index].quantity > 1) {
      updatedItems[index].quantity -= 1;
      setCartItems(updatedItems);
      localStorage.setItem('cart', JSON.stringify(updatedItems)); // Save to localStorage
    }
  };

  // Remove item from cart
  const removeItem = (index) => {
    const updatedItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedItems);
    localStorage.setItem('cart', JSON.stringify(updatedItems)); // Save to localStorage
  };

  // Handle size selection
  const selectSize = (index, size) => {
    const updatedItems = [...cartItems];
    updatedItems[index].selectedSize = size;
    setCartItems(updatedItems);
    localStorage.setItem('cart', JSON.stringify(updatedItems)); // Save to localStorage
  };

  // Handle checkout
  const handleCheckout = () => {
    if (cartItems.length === 0) {
      alert('Your cart is empty!');
      return;
    }

    const message = cartItems
      .map(item => 
        `- ${item.name} (${item.color}, Size: ${item.selectedSize}) x${item.quantity} = $${(item.base_price * item.quantity).toFixed(2)}`
      )
      .join('%0A');
    const total = cartItems.reduce((total, item) => total + parseFloat(item.base_price) * item.quantity, 0).toFixed(2);
    const fullMessage = `Hello, I would like to order the following items:%0A${message}%0A%0ATotal: $${total}`;
    const whatsappUrl = `https://wa.me/905362887229?text=${fullMessage}`;

    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="flex flex-col items-center justify-center bg-[#FDF5F2] px-4 sm:px-8">
      <div className="flex flex-col sm:items-center items-start ml-16 mb-0 mt-0 sm:justify-center w-full mb-0">
        <h1 className="text-2xl mt-0 mb-0 sm:text-4xl font-bold font-gothamBold text-[#3D291D] mt-4">Cart</h1>
        <IoIosArrowDown className="text-[#3D291D] text-2xl sm:text-4xl mt-2 hidden sm:block" />
      </div>
      {cartItems.map((item, index) => (
  <div key={index} className="w-full max-w-[50rem] h-[12rem] sm:h-[15rem] bg-[#FDF5F2] flex items-center px-4 sm:px-6 relative border-b-2 border-[#3D291D]">
    <div className="w-28 h-28 sm:w-44 sm:h-44 mr-4 sm:mr-12">
      <img src={item.images[0]?.image} alt={item.images[0]?.alt_text || item.name} className="w-full h-full object-cover" />
    </div>

    <div className="flex-grow">
      <div className="flex justify-between items-center mb-0">
        <h3 className="text-sm sm:text-3xl font-gothamBold font-bold text-[#3D291D]">
          {item.color} {item.name}
        </h3>

        <div className="absolute rounded-3xl right-1 sm:right-6 bg-[#FA8F21] flex items-center space-x-0">
          <button 
            onClick={() => removeItem(index)} 
            className="w-6 h-6 sm:w-10 sm:h-10 rounded-full bg-[#FA8F21] flex items-center justify-center"
          >
            <FiTrash size={14} className="sm:text-[20px]" color="#3D291D" />
          </button>
          <div className="flex items-center font-gothamBold justify-center w-6 h-6 sm:w-14 sm:h-10 rounded-full text-sm sm:text-xl text-[#3D291D]">
            <span>{item.quantity}</span>
          </div>
          <button 
            onClick={() => increaseQuantity(index)} 
            className="w-6 h-6 sm:w-10 sm:h-10 rounded-full bg-[#FA8F21] flex items-center justify-center"
          >
            <HiOutlinePlus size={14} className="sm:text-[20px]" color="#3D291D" />
          </button>
        </div>
      </div>

      <h3 className="text-lg sm:text-2xl font-gothamLight text-[#3D291D] mt-2 sm:mt-4">${item.base_price}</h3>

      {/* Conditionally render the size options based on the item name */}
      {item.name.toLowerCase() !== 'bag' && (
        <div className="flex space-x-3 sm:space-x-4 items-center mt-2 sm:mt-4">
          {['S', 'M', 'L'].map((size) => (
            <div
              key={size}
              onClick={() => selectSize(index, size)}
              className={`w-8 h-8 sm:w-10 sm:h-10 rounded-full border-2 flex items-center justify-center transition duration-200 cursor-pointer ${
                item.selectedSize === size ? 'bg-[#FA8F21] border-[#3D291D]' : 'border-[#3D291D]'
              }`}
            >
              <span className="font-gothamLight text-[#3D291D]">{size}</span>
            </div>
          ))}
        </div>
      )}

      <div className="absolute right-4 sm:right-6 flex items-center">
        <LuHeart size={24} sm={30} color="#FA8F21" />
        <TiArrowRight size={16} sm={20} color="#FA8F21" className="-ml-2 sm:-ml-3 mt-2 sm:mt-3" />
      </div>
    </div>
  </div>
))}


      <div className="flex justify-end w-full max-w-[45rem] mt-4 pr-4 sm:pr-6">
        <h3 className="text-lg sm:text-2xl font-gothamBold text-[#3D291D]">
          Total: ${cartItems.reduce((total, item) => total + parseFloat(item.base_price) * item.quantity, 0).toFixed(2)}
        </h3>
      </div>

      <div className="flex justify-end w-full max-w-[45rem] mt-4 pr-4 sm:pr-6">
      <input
  type="text"
  placeholder="Promo Code"
  className="w-32 sm:w-45 h-10 sm:h-12 bg-transparent text-sm font-gothamBold text-[#3D291D] mr-2 placeholder-[#3D291D] focus:outline-none"
  style={{
    borderTop: '0',
    borderLeft: '0',
    borderRight: '0',
    borderBottom: '2px solid #3D291D'
  }}
/>

        <span className="text-[#3D291D]">
          <FaArrowRight size={10} sm={13} className="-ml-1 mt-3 sm:mt-4" style={{ cursor: 'pointer' }} />
        </span>

        <button
  onClick={handleCheckout}
  className="w-32 sm:w-40 h-10 sm:h-12 ml-4 sm:ml-8 rounded-3xl bg-[#FA8F21] text-white text-sm sm:text-lg font-gothamBold flex items-center justify-center"
>
  Check Out ({cartItems.reduce((total, item) => total + item.quantity, 0)})
</button>

      </div>
      <hr className="w-full h-px bg-[#3D291D] mt-8" />
    </div>
  );
};

export default AddCart;
