import React, { useEffect, useState } from 'react';
import { LuHeart } from 'react-icons/lu'; 
import { FaHeart } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io'; 
import axios from 'axios';
import icon from '../Assets/icon.png';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import WhatsappContact from '../components/contact';
import LoginSignupModal from '../components/LoginSignupModal'

const Spinner = () => (
  <div className="w-10 h-10 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
);

const Products = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [colors, setColors] = useState([]); 
  const [selectedCategories, setSelectedCategories] = useState([]); 
  const [selectedColors, setSelectedColors] = useState([]); 
  const [isClicked, setIsClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [favorites, setFavorites] = useState([]); // State to hold favorites
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    setIsLoggedIn(!!token);

    const savedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(savedFavorites); // Load favorites on mount
    // Fetch products and categories
    axios.get(`https://saarconcept.net/api/products-with-filters/`)
      .then(response => {
        setProducts(response.data.products);
        setCategories(response.data.categories);
        const uniqueColors = Array.from(new Set([
          ...response.data.products.flatMap(product => product.product_colors.map(color => color.color.name)),
          "Black", "White"
        ]));
        setColors(uniqueColors);
      })
      .catch(error => console.error('Error fetching data:', error))
      .finally(() => setLoading(false));
}, []);
const handleActionClick = (actionType, product, color) => {
  const token = localStorage.getItem('access_token');
  
  if (!token) {
    setIsModalOpen(true); // Prompt login if not logged in
  } else {
    if (actionType === 'addToWishlist') {
      if (!color) {
        console.log('Color is undefined');
        return;
      }
      
      let updatedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
      
      // Check if the current product and color combination is already in the favorites
      const isFavorite = updatedFavorites.some(fav =>
        fav.id === product.id && fav.color.id === color.id
      );

      if (isFavorite) {
        updatedFavorites = updatedFavorites.filter(fav =>
          fav.id !== product.id || fav.color.id !== color.id
        );
      } else {
        updatedFavorites.push({
          id: product.id,
          name: product.name,
          color: { ...color },
          category: { id: product.category.id, name: product.category.name },
          base_price: product.base_price,
          product_colors: product.product_colors,
          images: color.images || [] // Save images if available
        });
      }

      setFavorites(updatedFavorites);
      localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    }
  }
};
const handleAddToCart = (product, color) => {
  const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
  const cartItem = {
    id: product.id,
    name: product.name,
    color: color.color.name,
    base_price: color.base_price,
    images: color.images || [],
  };
  
  // Add the product to cart
  cartItems.push(cartItem);
  localStorage.setItem('cart', JSON.stringify(cartItems));

  // Console log the added product
  console.log('Added to cart:', cartItem);
};
  const handleCategoryClick = (categoryId) => {
    setSelectedCategories(prevSelectedCategories => 
      prevSelectedCategories.includes(categoryId) 
        ? prevSelectedCategories.filter(id => id !== categoryId)
        : [...prevSelectedCategories, categoryId]
    );
  };

  const handleColorClick = (color) => {
    setSelectedColors(prevSelectedColors => 
      prevSelectedColors.includes(color) 
        ? prevSelectedColors.filter(c => c !== color)
        : [...prevSelectedColors, color]
    );
  };

  const clearFilters = () => {
    setSelectedCategories([]);
    setSelectedColors([]);
    setIsClicked(false);
  };

  const handleClick = () => {
    setIsClicked(true);
  };
 
  
  
  const filteredProducts = products.filter((product) => {
    const categoryMatch = selectedCategories.length > 0 
      ? selectedCategories.includes(product.category.id)
      : true;

    const colorMatch = selectedColors.length > 0 
      ? product.product_colors.some(color => selectedColors.includes(color.color.name))
      : true;

    return categoryMatch && colorMatch;
  });

  const handleProductClick = (slug, colorName) => {
    // Add a timeout to reload the page
    setTimeout(() => {
      window.location.reload(); // Reload the page
    }, 0);
    
    navigate(`/productDetail/${slug}?color=${colorName}`);

  };

  return (
    <div className="bg-[#FDF5F2] min-h-screen">
      <div className="flex flex-col items-center">
        <h1 className="text-4xl font-bold font-gothamBold text-[#0F2B2A] mt-4">Products</h1>
        <IoIosArrowDown className="text-[#0F2B2A] text-4xl mt-2 hidden sm:block" />
      </div>

      <div className="flex flex-col lg:flex-row lg:mx-32 py-8">
        {/* Filters Section */}
        <div className="w-full lg:w-1/6 lg:block lg:mr-4 mb-4 lg:mb-0">
          <div className="flex items-center mb-4">
            <div className="mr-2">
              <img src={icon} alt="Filter Icon" className="w-6 h-6 hidden lg:block" />
            </div>
            <h2 className="text-2xl font-bold font-gothamBold text-[#3D291D] hidden lg:block">Filters</h2>
          </div>

          <div className="mb-6">
  <h3 className="text-lg font-bold font-gothamBold text-[#3D291D] mb-2 hidden lg:block">Colors</h3>
  <ul className="flex flex-wrap">
    {colors.map((color) => {
      const colorHex = {
        "Navy Blue": "#173560",
        "Olive Green": "#7BA785",
        "Off White": "#FFF7F5",
        "Black": "#000000",
        "White": "#FFFFFF"
      }[color];

      const borderStyle = selectedColors.includes(color)
        ? "border-2 border-[#0F2B2A]"
        : "border-1 border-[#0F2B2A]";

      return (
        <li key={color} className="mr-1 mb-2">
          <button
            aria-label={color}
            className={`rounded-full transition-opacity duration-200 ease-in-out ${borderStyle} ${selectedColors.includes(color) ? 'opacity-75' : ''}`}

            onClick={() => handleColorClick(color)}
            style={{
              backgroundColor: colorHex,
              width: '40px',
              height: '40px',
            }}
          />
        </li>
      );
    })}
  </ul>
</div>


          {/* Category Filter */}
          <div className="mb-6">
            <h3 className="text-lg font-bold font-gothamBold text-[#3D291D] mb-2 hidden lg:block">Categories</h3>
            <ul className="flex flex-wrap">
              {categories.map((category) => (
                <li key={category.id} className="mr-2 mb-2">
                  <button 
                className={`text-sm font-gothamLight text-[#3D291D] border-1 border-[#3D291D] rounded-3xl py-1 px-2 
                ${selectedCategories.includes(category.id) ? 'bg-[#FA8F21] text-white' : 'bg-transparent'}
                hover:bg-[#FA8F21] transition-colors duration-200 ease-in-out cursor-pointer`}
    
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div
  onClick={() => {
    handleClick(); 
    clearFilters();
  }} 
  className="text-[#3D291D] 
    font-gothamBold 
    text-sm md:text-md
    hover:bg-[#3D291D] 
    hover:text-white
    border-3 
    border-[#3D291D] 
    ml-6
    rounded-full 
    px-3 py-1 md:px-2 md:py-2 
    transition 
    duration-300 
    no-underline 
    decoration-none 
    hover:bg-[#3D291D] 
    hover:text-white
    flex justify-center items-center
    w-auto 
    max-w-[200px]" // Limit the max width on mobile
>
  <span>Clear Filters</span>
</div>

        </div>
{/* Products Section */}
<div className="w-full lg:w-5/6 lg:ml-8">
          {loading ? (
            <div className="flex justify-center items-center min-h-[300px]">
              <Spinner />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-2 sm:gap-3 lg:gap-4 sm:grid-cols-3 lg:grid-cols-4">
              {filteredProducts.map((product) =>
                product.product_colors
                  .filter(color => selectedColors.length === 0 || selectedColors.includes(color.color.name))
                  .map((color) =>
                    color.images.map((image) => (
                      <div key={image.id} className="flex flex-col items-start p-2 mb-2 no-underline">
                        <div className="w-full h-48">
                          <img
                            src={image.image}
                            alt={image.alt_text}
                            className="w-full h-full object-cover"
                            onClick={() => handleProductClick(product.slug, color.color.name)}
                          />
                        </div>

                        <div className="w-full flex justify-between items-center mb-2">
                          <div>
                            <h2 className="text-xl font-bold font-gothamBold text-[#3D291D]">
                              {color.color.name} {product.name}
                            </h2>
                            <p className="text-lg font-light font-gothamLight text-[#3D291D]">
                              {color.base_price}$
                            </p>
                          </div>
                        </div>

                        {/* Wishlist Button */}
                       
<div className="flex justify-between w-full">
<button
                            onClick={() => handleAddToCart(product, color)}
                            className="mt-0 bg-[#FA8F21] font-bold font-gothamBold text-white py-2 px-3 rounded-3xl">
                            Add to Cart
                          </button>

  {/* Wishlist Button */}
  {favorites.some(fav => fav.id === product.id && fav.color === color.color.name) ? (
    <FaHeart
      onClick={() => handleActionClick('addToWishlist', product, color)}
      className="text-[#FA8F21] text-2xl mt-2 cursor-pointer"
    />
  ) : (
    <LuHeart
      onClick={() => handleActionClick('addToWishlist', product, color)}
      className="text-[#FA8F21] text-2xl mt-2 cursor-pointer"
    />
  )}
</div>
                      </div>
                    ))
                  )
              )}
            </div>
          )}
        </div>
      </div>
      <LoginSignupModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <div className="fixed bottom-32 right-4 lg:right-8 z-50">
        <WhatsappContact />
      </div>
    </div>
  );
};

export default Products;